<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteSlider($event, null , true)"
    />

    <vs-popup
      title="تفاصيل السلايدر"
      :active.sync="popupActive"
      :button-close-hidden="true"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <h4 class="my-5">
                الصورة
              </h4>
              <image-uploader
                @fileUploaded="fileUploaded"
                :image-preview="imagePreview"
                :is-image-required="imageRequired"
                ref="imageUploader"
              />
            </div>
          </div>
          <div class="vx-row justify-center">
            <div class="vx-col w-full">
              <ValidationProvider
                name="العنوان"
                rules="required"
                v-slot="{ errors }"
              >
                <label>العنوان</label>
                <vs-input
                  placeholder="العنوان"
                  name="title"
                  v-model="slider.title"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row justify-center">
            <div class="vx-col w-full">
              <ValidationProvider
                name="النوع"
                rules="required"
                v-slot="{ errors }"
              >
                <label>النوع</label>
                <v-select
                  label="type"
                  :options="sliderTypes"
                  @input="selected"
                  :value="slider.type"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vs-row">
            <div class="vx-col w-full">
              <ValidationProvider
                name="التفاصيل"
                rules="required"
                v-slot="{ errors }"
              >
                <label>التفاصيل</label>
                <vs-input
                  placeholder="التفاصيل"
                  v-model="slider.desc"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup
      title="تفاصيل السلايدر"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div class="flex items-center justify-center mt-2">
        <!-- <img :src="slider.image_url" class="m-auto custom-image-size"> -->
        <img
          :src="slider.image_url"
          class="m-auto custom-image-size"
        >
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-6 px-4 mt-6">
        <div>
          <label>المعرّف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="slider.id"
          />
        </div>

        <div>
          <label>العنوان :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="slider.title"
          />
        </div>

        <div>
          <label>النوع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="slider.type"
          />
        </div>

        <div class="col-span-3">
          <label>التفاصيل :</label>
          <vs-textarea
            placeholder=""
            v-model="slider.desc"
            disabled
            class="w-full mt-3"
            color="#5E445A"
          />
        </div>
      </div>
      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import AgTableImageCell from '../../shared/shared-components/ag-grid/AgTableImageCell';
import {SharedRepositoryFactory} from "../shared-repositories/sharedRepositoryFactory";
import vSelect from 'vue-select';
import imageUploader from '../../shared/shared-components/ImageUploader';
import utilities from '../../shared/utilities/index';

const sliderRepo = SharedRepositoryFactory.get('slider');

export default {
  name: "Slider",
  components: {
    AgTable,
    vSelect,
    imageUploader
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      imageRequired: true,
      imagePreview: null,
      dialogTitle: String,
      sliderTypes: [
        'Intro',
        'Gift'
      ],
      slider: {
        id: null,
        name: '',
        image_url: '',
        title: '',
        desc: '',
        type: '',
      }

    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'العنوان',
          field: 'title',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الصورة',
          field: 'image_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'النوع',
          field: 'type',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteSlider(id, rowIndex);
            },
            editRecord: function (market) {
              self.editRecord(market);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit', 'delete']
          },

        },
      ];
    },
    deleteSlider(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      sliderRepo.deleteSlider(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllSliders();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      });
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.slider = record;
    },
    fileUploaded(file) {
      this.slider.image_url = file;
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    selected(type) {
      this.slider.type = type;
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.slider);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.dialogTitle = 'اضافة سلايدر';
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(slider) {
      this.$refs.imageUploader.clearImage();
      this.dialogTitle = 'تعديل سلايدر';
      Object.assign(this.slider, slider);
      this.imagePreview = this.slider.image_url;
      this.openPopup();
    },

    onSubmit() {
      this.isLoading = true;
      if (typeof this.slider.image_url == "string")
        delete this.slider.image_url;
      if (this.slider.id !== null) {
        this.updateSlider(this.slider);
      } else {
        this.storeSlider(this.slider);
      }
    },

    storeSlider(slider) {
      sliderRepo.storeSlider(this.$objectToFD(slider)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    updateSlider(slider) {
      sliderRepo.updateSlider(this.$objectToFD(slider), slider.id).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    fetchAllSliders() {
      sliderRepo.fetchAllSliders().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllSliders();

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

